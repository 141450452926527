import { useEffect, useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Navbar } from "../../Components/Navbar/Navbar";
import { News_EN as NewsData } from "../../Constants/News";
import "./News.styles.css";

const News = () => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const pages = Array((NewsData.length / 9).toFixed());
  const language = i18next.language;

  useEffect(() => {
    document.title = "News - Cevher";
  }, []);

  const onPageChange = (ind) => {
    document.getElementById("page-prev").classList.remove("is-active");
    document.getElementById("page-next").classList.remove("is-active");
    for (let i = 0; i < pages; i++) {
      document.getElementById(`page-${i + 1}`).classList.remove("is-active");
    }
    setPageNumber(ind);
    document.getElementById(`page-${ind}`).classList.add("is-active");
    document.getElementById("hero").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero" id="hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/news.2f117a4c.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("news")}
              </div>
              <h1 className="c-caption-header">{t("news-cevher")}</h1>
            </div>
          </div>
        </div>
        <div className="c-container news-container">
          {NewsData.slice((pageNumber - 1) * 9, pageNumber * 9).map((news) => {
            return (
              <Link
                to={{ pathname: `/${news.link}`, state: { data: news } }}
                className="news-card"
                key={news.title}
              >
                <div className="c-ratio--16-9 c-ratio">
                  <picture>
                    <img src={news.img} className="c-news-img" alt="#" />
                  </picture>
                </div>
                <div className="c-news-body">
                  <div className="c-news-title">
                    {language === "en" ? news.title : news.title_tr}
                  </div>
                  <div className="c-news-date">{news.date}</div>
                  <div className="c-news-content content">
                    {language === "en" ? news.desc : news.desc_tr}
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        <div
          className="c-container"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ul className="c-pagination">
            <li className="c-pagination-item" id="page-prev">
              <div
                className={
                  pageNumber !== 1
                    ? "c-pagination-link"
                    : "c-pagination-link disabled"
                }
                onClick={() => onPageChange(pageNumber - 1)}
              >
                {"<"}
              </div>
            </li>
            <li className="c-pagination-item is-active" id="page-1">
              <div
                className="c-pagination-link"
                onClick={() => onPageChange(1)}
              >
                1
              </div>
            </li>
            <li className="c-pagination-item" id="page-2">
              <div
                className="c-pagination-link"
                onClick={() => onPageChange(2)}
              >
                2
              </div>
            </li>
            <li className="c-pagination-item" id="page-3">
              <div
                className="c-pagination-link"
                onClick={() => onPageChange(3)}
              >
                3
              </div>
            </li>
            <li className="c-pagination-item">
              <div
                id="page-next"
                className={
                  pageNumber !== 2
                    ? "c-pagination-link"
                    : "c-pagination-link disabled"
                }
                onClick={() => onPageChange(pageNumber + 1)}
              >
                {">"}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default News;
