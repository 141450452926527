import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./SocialResponsibility.css";

const Social = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Social Responsibility - Cevher";
  }, []);

  return (
    <div>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/social-responsibility.4ec565f5.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("corporate")}
              </div>
              <h1 className="c-caption-header">{t("corporate-social")}</h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
        >
          <div className="c-col--offset-lg-2 c-col--lg-8">
            <p>
              <strong>{t("corporate-social-title")}</strong>
            </p>
            <ul className="c-list">
              <li className="c-list-item">
                {t("corporate-social-1")}
              </li>
              <li className="c-list-item">
                {t("corporate-social-2")}
              </li>
              <li className="c-list-item">
                {t("corporate-social-3")}
              </li>
              <li className="c-list-item">
                {t("corporate-social-4")}
              </li>
              <li className="c-list-item">
                {t("corporate-social-5")}
              </li>
              <li className="c-list-item">
                {t("corporate-social-6")}
              </li>
              <li className="c-list-item">
                {t("corporate-social-7")}
              </li>
              <li className="c-list-item">
                {t("corporate-social-8")}
              </li>
              <li className="c-list-item">
                {t("corporate-social-9")}
              </li>
              <li className="c-list-item">
                {t("corporate-social-10")}
              </li>
              <li className="c-list-item">
                {t("corporate-social-11")}
              </li>
              <li className="c-list-item">
                {t("corporate-social-12")}
              </li>
            </ul>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div
                className="c-social-img-wrapper"
                style={{
                  textAlign: "center",
                  position: "relative",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <img
                  src={require("../../assets/content/cbu_egitim.jpg")}
                  alt="Social"
                  style={{
                    marginTop: "1.5rem",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </div>
              <div
                className="c-social-img-wrapper"
                style={{
                  textAlign: "center",
                  position: "relative",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <img
                  src={require("../../assets/content/deu_ders.jpg")}
                  alt="Social"
                  style={{
                    marginTop: "1.5rem",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </div>
              <div
                className="c-social-img-wrapper"
                style={{
                  textAlign: "center",
                  position: "relative",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <img
                  src={require("../../assets/content/kizilay.jpg")}
                  alt="Social"
                  style={{
                    marginTop: "1.5rem",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </div>
              <div
                className="c-social-img-wrapper"
                style={{
                  textAlign: "center",
                  position: "relative",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <img
                  src={require("../../assets/content/solaris.JPG")}
                  alt="Social"
                  style={{
                    marginTop: "1.5rem",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </div>
              <div
                className="c-social-img-wrapper"
                style={{
                  textAlign: "center",
                  position: "relative",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <img
                  src={require("../../assets/content/Solaris5.jpg")}
                  alt="Social"
                  style={{
                    marginTop: "1.5rem",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Social;
