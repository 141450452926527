import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";

const Quality = () => {
    const { t } = useTranslation();
    const language = i18next.language;

    useEffect(() => {
        document.title = "Quality - Cevher";
    }, []);

    return (
        <div>
            <Navbar />
            <div className="c-wrapper">
                <div className="c-hero">
                    <div className="c-hero-background">
                        <picture>
                            <img
                                className="c-hero-image"
                                alt=""
                                src={require("../../assets/hero/quality.de63555a.jpg")}
                            />
                        </picture>
                    </div>
                    <div className="c-hero-body">
                        <div className="c-about-caption">
                            <div
                                className="c-caption-section"
                                style={{ color: "#fff", textAlign: "center" }}
                            >
                                {t("developmentandproduction")}
                            </div>
                            <h1 className="c-caption-header">{t("quality")}</h1>
                        </div>
                    </div>
                </div>
                <div className="c-container">
                    <div className="c-col--offset-lg-2 c-col--lg-8">
                        <div style={{ marginTop: "2.5rem" }}>
                            <p>
                                <strong>{t("development-quality-title")}</strong>
                            </p>
                        </div>
                        <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                            <div style={{ marginBottom: "1rem" }}>
                                <strong>{t("development-quality-ourvision")}</strong>
                            </div>
                            <p>{t("development-quality-ourvision-desc")}</p>
                        </div>
                        <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                            <div style={{ marginBottom: "1rem" }}>
                                <strong>{t("development-quality-ourmission")}</strong>
                            </div>
                            <ul className="c-list" style={{ listStyle: "inside" }}>
                                <li className="c-list-item">
                                    {t("development-quality-ourmission-1")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourmission-2")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourmission-3")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourmission-4")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourmission-5")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourmission-6")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourmission-7")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourmission-8")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourmission-9")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourmission-10")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourmission-11")}
                                </li>
                            </ul>
                        </div>
                        <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                            <div style={{ marginBottom: "1rem" }}>
                                <strong>{t("development-quality-ourvalues")}</strong>
                            </div>
                            <ul
                                className="c-list"
                                style={{
                                    columnCount: 2,
                                    columnGap: "2rem",
                                    listStyle: "inside",
                                }}
                            >
                                <li className="c-list-item">
                                    {t("development-quality-ourvalues-1")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourvalues-2")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourvalues-3")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourvalues-4")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourvalues-5")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-ourvalues-6")}
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="c-row row--no-gutter">
                                <div className="c-col c-col--lg-6">
                                    <img
                                        src={require("../../assets/content/1- CASS Testi - Salt Spray and Alternating Climate Test.jpg")}
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                    />
                                </div>
                                <div className="c-col c-col--lg-6">
                                    <img
                                        src={require("../../assets/content/2- Cok Eksenli Yorulma Testi (Multi-Axial Fatigue Test (ZWARP)).jpg")}
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                    />
                                </div>
                            </div>
                            <div
                                className="c-row row--no-gutter"
                                style={{ marginTop: "1.5rem" }}
                            >
                                <div className="c-col c-col--lg-12">
                                    <img
                                        src={require("../../assets/content/4- 3D Olcum Cihazi (3D Measurement Device).jpg")}
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
                <div className="c-container">
                    <div className="c-col--offset-lg-2 c-col--lg-8">
                        <div style={{ fontSize: "2em", fontWeight: "bold" }}>
                            {t("development-quality-testing")}
                        </div>
                        <div>{t("development-quality-testing-desc")}</div>
                        <div
                            style={{
                                fontSize: "2em",
                                fontWeight: "bold",
                                marginTop: "1.5rem",
                            }}
                        >
                            {t("development-quality-list-title")}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                            }}
                        >
                            <div style={{ maxWidth: "50%", flex: "0 0 50%" }}>
                                <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                                    <div style={{ marginBottom: "1rem" }}>
                                        <strong>{t("development-quality-mechanical")}</strong>
                                    </div>
                                    <ul className="c-list" style={{ listStyle: "none" }}>
                                        <li className="c-list-item">
                                            {t("development-quality-mechanical-0")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-mechanical-1")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-mechanical-2")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-mechanical-3")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-mechanical-4")}
                                        </li>
                                    </ul>
                                </div>
                                <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                                    <div style={{ marginBottom: "1rem" }}>
                                        <strong>{t("production")}</strong>
                                    </div>
                                    <ul className="c-list" style={{ listStyle: "none" }}>
                                        <li className="c-list-item">
                                            {t("development-quality-production-1")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-production-2")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-production-3")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-production-4")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-production-5")}
                                        </li>
                                    </ul>
                                </div>
                                <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                                    <div style={{ marginBottom: "1rem" }}>
                                        <strong>{t("development-quality-dimensional")}</strong>
                                    </div>
                                    <ul className="c-list" style={{ listStyle: "none" }}>
                                        <li className="c-list-item">
                                            {t("development-quality-dimensional-1")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-dimensional-2")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-dimensional-3")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-dimensional-4")}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div style={{ maxWidth: "50%", flex: "0 0 50%" }}>
                                <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                                    <div style={{ marginBottom: "1rem" }}>
                                        <strong>{t("development-quality-metallurgy")}</strong>
                                    </div>
                                    <ul className="c-list" style={{ listStyle: "none" }}>
                                        <li className="c-list-item">
                                            {t("development-quality-metallurgy-1")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-metallurgy-2")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-metallurgy-3")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-metallurgy-4")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-metallurgy-5")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-metallurgy-6")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-metallurgy-7")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-metallurgy-8")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-metallurgy-9")}
                                        </li>
                                    </ul>
                                </div>
                                <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                                    <div style={{ marginBottom: "1rem" }}>
                                        <strong>{t("development-quality-point")}</strong>
                                    </div>
                                    <ul className="c-list" style={{ listStyle: "none" }}>
                                        <li className="c-list-item">
                                            {t("development-quality-point-1")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-2")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-3")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-4")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-5")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-6")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-7")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-8")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-9")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-10")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-11")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-12")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-13")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-14")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-15")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-16")}
                                        </li>
                                        <li className="c-list-item">
                                            {t("development-quality-point-17")}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
                <div className="c-container">
                    <div className="c-col--offset-lg-2 c-col--lg-8">
                        <div style={{ marginBottom: "6rem" }}>
                            <div style={{ fontSize: "1.5em", fontWeight: "500" }}>
                                {t("development-quality-certificates")}
                            </div>
                            <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                                {t("development-quality-certificates-desc")}
                            </div>
                            <ul className="c-list" style={{ listStyle: "inside" }}>
                                <li className="c-list-item">
                                    {t("development-quality-certificates-1")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-certificates-2")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-certificates-3")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-certificates-4")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-certificates-5")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-certificates-6")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-certificates-7")}
                                </li>
                                <li className="c-list-item">
                                    {t("development-quality-certificates-8")}
                                </li>
                                <li className="c-list-item">
                                    {t("certificate-of-registration")}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="c-container">
                    <div className="c-col--offset-lg-2 c-col--lg-8">
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                                justifyContent: "center",
                                gap: 8,
                            }}
                        >
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/ISO_14064_EN.png")
                                        : require("../../assets/certificates/ISO_14064_EN.png")
                                }
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/ISO_14064_EN.png")
                                            : require("../../assets/certificates/ISO_14064_EN.png")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/EN 9100-1.jpg")
                                        : require("../../assets/certificates/EN 9100-1.jpg")
                                }
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/EN 9100-1.jpg")
                                            : require("../../assets/certificates/EN 9100-1.jpg")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/EN9100_Fab2.png")
                                        : require("../../assets/certificates/EN9100_Fab2.png")
                                }
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/EN9100_Fab2.png")
                                            : require("../../assets/certificates/EN9100_Fab2.png")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/IATF 16949_English-1.jpg")
                                        : require("../../assets/certificates/IATF 16949_TR-1.jpg")
                                }
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/IATF 16949_English-1.jpg")
                                            : require("../../assets/certificates/IATF 16949_TR-1.jpg")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/ISO 9001_English-1.jpg")
                                        : require("../../assets/certificates/ISO 9001_TR-1.jpg")
                                }
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/ISO 9001_English-1.jpg")
                                            : require("../../assets/certificates/ISO 9001_TR-1.jpg")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/ISO_9001_Eng_Fab2.png")
                                        : require("../../assets/certificates/Fab2_ISO9001_TR.png")
                                }
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/ISO_9001_Eng_Fab2.png")
                                            : require("../../assets/certificates/Fab2_ISO9001_TR.png")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/ISO_14001_En.png")
                                        : require("../../assets/certificates/ISO_14001_TR.png")
                                }
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/ISO_14001_En.png")
                                            : require("../../assets/certificates/ISO_14001_TR.png")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/ISO_27001_En.png")
                                        : require("../../assets/certificates/ISO_27001_TR.png")
                                }
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/ISO_27001_En.png")
                                            : require("../../assets/certificates/ISO_27001_TR.png")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/ISO 45001 Eng-1.jpg")
                                        : require("../../assets/certificates/ISO 45001 TR-1.jpg")
                                }
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/ISO 45001 Eng-1.jpg")
                                            : require("../../assets/certificates/ISO 45001 TR-1.jpg")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/ISO_50001_ENG.png")
                                        : require("../../assets/certificates/ISO_50001_TR.png")
                                }
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/ISO_50001_ENG.png")
                                            : require("../../assets/certificates/ISO_50001_TR.png")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/ISO_50001_ENG_FAB2.png")
                                        : require("../../assets/certificates/ISO_50001_TR_FAB2.png")
                                }
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/ISO_50001_ENG_FAB2.png")
                                            : require("../../assets/certificates/ISO_50001_TR_FAB2.png")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/30106-A-01 - CEVHER JANT SA8000 Certificate-1.png")
                                        : require("../../assets/certificates/30106-A-01 - CEVHER JANT SA8000 Certificate-1.png")
                                }
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/30106-A-01 - CEVHER JANT SA8000 Certificate-1.png")
                                            : require("../../assets/certificates/30106-A-01 - CEVHER JANT SA8000 Certificate-1.png")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                        </div>
                        <div
                            style={{
                                marginTop: "1.5rem",
                                display: "flex",
                                flexWrap: "wrap",
                            }}
                        >
                            <div className="c-col--lg-2 c-col--xs-6">
                                <div
                                    style={{
                                        position: "relative",
                                        height: 125,
                                        border: 0,
                                        textAlign: "right",
                                    }}
                                >
                                    <img
                                        src={require("../../assets/certificates/tuv-nord.f51c13b3.png")}
                                        alt="TUV Nord"
                                        className="c-logo-img"
                                    />
                                </div>
                            </div>
                            <div className="c-col--lg-2 c-col--xs-6">
                                <div style={{ position: "relative", height: 125, border: 0 }}>
                                    <img
                                        src={require("../../assets/certificates/tse.3c687945.png")}
                                        alt="Turkish Standards Institue"
                                        style={{ marginLeft: "2rem" }}
                                        className="c-logo-img"
                                    />
                                </div>
                            </div>
                            <div className="c-col--lg-2 c-col--xs-6">
                                <div style={{ position: "relative", height: 125, border: 0 }}>
                                    <img
                                        src={require("../../assets/certificates/din.544001fd.png")}
                                        alt="DIN"
                                        style={{ marginLeft: "2rem" }}
                                        className="c-logo-img"
                                    />
                                </div>
                            </div>
                            <div className="c-col--lg-2 c-col--xs-6">
                                <div style={{ position: "relative", height: 125, border: 0 }}>
                                    <img
                                        src={require("../../assets/certificates/sni.fc37a585.png")}
                                        alt="SNI"
                                        style={{ marginLeft: "2rem" }}
                                        className="c-logo-img"
                                    />
                                </div>
                            </div>
                            <div className="c-col--lg-2 c-col--xs-6">
                                <div style={{ position: "relative", height: 125, border: 0 }}>
                                    <img
                                        src={require("../../assets/certificates/astm.a6e265b3.png")}
                                        alt="ASTM International"
                                        style={{ marginLeft: "2rem" }}
                                        className="c-logo-img"
                                    />
                                </div>
                            </div>
                            <div className="c-col--lg-2 c-col--xs-6">
                                <div style={{ position: "relative", height: 125, border: 0 }}>
                                    <img
                                        src={require("../../assets/certificates/inmetro.7547c404.png")}
                                        alt="INMETRO"
                                        style={{ marginLeft: "2rem" }}
                                        className="c-logo-img"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Quality;
