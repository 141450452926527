import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";

const RND = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "R&D and Engineering - Cevher";
  }, []);

  return (
    <div>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/rnd.b023d819.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("developmentandproduction")}
              </div>
              <h1 className="c-caption-header">{t("r&dandengineering")}</h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <div className="c-row row--no-gutter u-flex-row-reverse" style={{ paddingTop: "4rem" }}>
            <div className="col c-col--lg-6">
              <div className="c-caption-container">
                <div className="c-caption">
                  <div className="c-caption-title">
                    {t("development-rnd-product-title")}
                  </div>
                  <div className="c-caption-content">
                    <p>
                      <strong>{t("development-rnd-product-bold")}</strong>
                    </p>
                    <p>{t("development-rnd-product-desc")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/content/rnd-1.40517464.png")}
                  alt="Product-1"
                  className="c-products-img"
                />
              </div>
            </div>
          </div>
          <div className="c-row row--no-gutter " style={{ paddingTop: "4rem" }}>
            <div className="col c-col--lg-6">
              <div className="c-caption-container">
                <div className="c-caption">
                  <div className="c-caption-title">
                    {t("development-rnd-mold-title")}
                  </div>
                  <div className="c-caption-content">
                    <p>
                      <strong>{t("development-rnd-mold-bold")}</strong>
                    </p>
                    <p>{t("development-rnd-mold-desc")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/content/rnd-2.0c3f01d7.png")}
                  alt="Product-1"
                  className="c-products-img"
                />
              </div>
            </div>
          </div>
          <div className="c-row row--no-gutter u-flex-row-reverse" style={{ paddingTop: "4rem" }}>
            <div className="col c-col--lg-6">
              <div className="c-caption-container">
                <div className="c-caption">
                  <div className="c-caption-title">
                    {t("development-rnd-rnd-title")}
                  </div>
                  <div className="c-caption-content">
                    <p>
                      <strong>{t("development-rnd-rnd-bold")}</strong>
                    </p>
                    <p>{t("development-rnd-rnd-desc")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/content/rnd-3.0f125296.png")}
                  alt="Product-1"
                  className="c-products-img"
                />
              </div>
            </div>
          </div>
          <div className="c-row row--no-gutter" style={{ paddingTop: "4rem" }}>
            <div className="col c-col--lg-6">
              <div className="c-caption-container">
                <div className="c-caption">
                  <div className="c-caption-title">
                    {t("development-rnd-optimal-title")}
                  </div>
                  <div className="c-caption-content">
                    <p>
                      <strong>{t("development-rnd-optimal-bold")}</strong>
                    </p>
                    <p>{t("development-rnd-optimal-desc")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/content/rnd-4.f153c923.png")}
                  alt="Product-1"
                  className="c-products-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RND;
