import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";

const ISO = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = "Sustainability - Cevher";
    }, []);

    return (
        <div>
            <Navbar />
            <div className="c-wrapper">
                <div className="c-hero">
                    <div className="c-hero-background">
                        <picture>
                            <img
                                className="c-hero-image"
                                alt=""
                                src={require("../../assets/hero/iso-14001-2015-scope.9e5c5e79.jpg")}
                            />
                        </picture>
                    </div>
                    <div className="c-hero-body">
                        <div className="c-about-caption">
                            <div
                                className="c-caption-section"
                                style={{ color: "#fff", textAlign: "center" }}
                            >
                                {t("sustainability")}
                            </div>
                            <h1 className="c-caption-header">{t("isoscope")}</h1>
                        </div>
                    </div>
                </div>
                <div
                    className="c-container"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: "3rem",
                        paddingBottom: "3rem",
                    }}
                >
                    <p>
                        <strong style={{ textAlign: "start" }}>{t("sustainability-iso-title")}</strong>
                    </p>
                    <p>{t("sustainability-iso-1")}</p>
                    <p>{t("sustainability-iso-2")}</p>
                    <p>
                        {t("sustainability-iso-3")}
                        <br />
                        <b style={{ textDecoration: 'underline' }}>{t("sustainability-iso-fab1")}</b>
                        {t("sustainability-iso-4")}
                        <br />
                        {t("sustainability-iso-5")}
                        <br />
                        {t("sustainability-iso-6")}
                        <br />
                        {t("sustainability-iso-7")}
                        <br />
                        {t("sustainability-iso-8")}
                        <b style={{ textDecoration: 'underline' }}>{t("sustainability-iso-fab2")}</b>
                        {t("sustainability-iso-4-2")}
                        <br />
                        {t("sustainability-iso-5-2")}
                        <br />
                        {t("sustainability-iso-6-2")}
                        <br />
                        {t("sustainability-iso-7-2")}
                        <br />
                        {t("sustainability-iso-8-2")}
                    </p>
                    <p>
                        {t("sustainability-iso-9")}
                        <br />
                    </p>
                    <p>{t("sustainability-iso-10")}</p>
                    <p>
                        <br />
                        {t("sustainability-iso-11")}
                    </p>
                    <p>
                        <img
                            style={{ maxWidth: "80%" }}
                            alt="cevher_coordinate"
                            src={require("../../assets/uploads/cevher-koordinat.png")}
                        />
                    </p>
                    <p>
                        <br />
                        {t("sustainability-iso-12")}
                    </p>
                    <p>
                        <br />
                        {t("sustainability-iso-12-2")}
                    </p>
                    <p>{t("sustainability-iso-13")}</p>
                    <p>{t("sustainability-iso-13-2")}</p>
                    <p>{t("sustainability-iso-14")}</p>
                    <em>
                        <br />
                        {t("sustainability-iso-15")}
                    </em>
                </div>
            </div>
        </div>
    );
};

export default ISO;
