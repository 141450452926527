import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import Timeline from "../../Components/Timeline";

const History = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className="c-hero">
        <div className="c-hero-background">
          <picture>
            <img
              className="c-hero-image"
              alt=""
              src={require("../../assets/hero/history.387483c7.jpg")}
            />
          </picture>
        </div>
        <div className="c-hero-body">
          <div className="c-about-caption">
            <div
              className="c-caption-section"
              style={{ color: "#fff", textAlign: "center" }}
            >
              {t("corporate")}
            </div>
            <h1 className="c-caption-header">{t("corporate-history")}</h1>
          </div>
        </div>
      </div>
      <div className="c-container">
        <div style={{ position: "relative", overflow: "hidden" }}>
          <div className="c-timeline-lines" />
          <Timeline scrollable={false} />
        </div>
      </div>
    </>
  );
};

export default History;
