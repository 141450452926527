import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import { CustomerList } from "../../Constants/Customers";
import "./Customers.styles.css";

const Customers = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Our Clients - Cevher";
  }, []);

  return (
    <div>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/clients.e83f24f3.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("corporate")}
              </div>
              <h1 className="c-caption-header">{t("ourcustomers")}</h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <div className="c-col--offset-lg-2 c-col--lg-8">
            <p>
              <strong>{t("corporate-clients-1")}</strong>
            </p>
            <p>{t("corporate-clients-2")}</p>
          </div>
          <div
            className="c-container"
            style={{ marginTop: "6rem", width: "90%" }}
          >
            <div className="c-customers-wrapper">
              {CustomerList.map((customer) => (
                <div className="c-customers-item">
                  <img
                    src={customer.img}
                    alt={customer.alt}
                    className="c-customer-logo"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
