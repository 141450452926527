import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Navbar } from "../../Components/Navbar/Navbar";

const Production = () => {
  const { t } = useTranslation();

  const changePic = (id, type, max) => {
    document.getElementById(`${id}-${type}`).classList.add("is-active");
    document.getElementById(`${id}-${type}-thumb`).classList.add("is-active");
    for (let i = 1; i <= max; i++) {
      if (i !== id) {
        document.getElementById(`${i}-${type}`).classList.remove("is-active");
        document
          .getElementById(`${i}-${type}-thumb`)
          .classList.remove("is-active");
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/production.a1b80b33.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("developmentandproduction")}
              </div>
              <h1 className="c-caption-header">{t("production")}</h1>
            </div>
          </div>
        </div>
        <div className="c-container ">
          <div className="c-row row--no-gutter u-flex-row-reverse" style={{ paddingTop: "4rem" }}>
            <div className="col c-col--lg-6">
              <div className="c-caption-container">
                <div className="c-caption">
                  <div className="c-caption-title">
                    {t("development-production-foundry")}
                  </div>
                  <div className="c-caption-content">
                    {t("development-production-foundry-desc")}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={require("../../assets/content/production/foundry/1- Dokumhane (Foundry).jpg")}
                      alt=""
                      className="c-thumb-slider-item is-active"
                      id="1-foundry-thumb"
                      onClick={() => changePic(1, "foundry", 7)}
                    />
                    <img
                      src={require("../../assets/content/production/foundry/2- Dokumhane (Foundry).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="2-foundry-thumb"
                      onClick={() => changePic(2, "foundry", 7)}
                    />
                    <img
                      src={require("../../assets/content/production/foundry/3 - Ergitme (Melting).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="3-foundry-thumb"
                      onClick={() => changePic(3, "foundry", 7)}
                    />
                    <img
                      src={require("../../assets/content/production/foundry/4 - Ergitme (Melting).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="4-foundry-thumb"
                      onClick={() => changePic(4, "foundry", 7)}
                    />
                    <img
                      src={require("../../assets/content/production/foundry/5- Ergitme (Melting).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="5-foundry-thumb"
                      onClick={() => changePic(5, "foundry", 7)}
                    />
                    <img
                      src={require("../../assets/content/production/foundry/6- X-Ray (X-Ray).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="6-foundry-thumb"
                      onClick={() => changePic(6, "foundry", 7)}
                    />
                    <img
                      src={require("../../assets/content/production/foundry/7 - Isil Islem (Heat Treatment).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="7-foundry-thumb"
                      onClick={() => changePic(7, "foundry", 7)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/content/production/foundry/1- Dokumhane (Foundry).jpg")}
                  alt="Product-1"
                  className="c-production-img is-active"
                  id="1-foundry"
                />
                <img
                  src={require("../../assets/content/production/foundry/2- Dokumhane (Foundry).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="2-foundry"
                />
                <img
                  src={require("../../assets/content/production/foundry/3 - Ergitme (Melting).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="3-foundry"
                />
                <img
                  src={require("../../assets/content/production/foundry/4 - Ergitme (Melting).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="4-foundry"
                />
                <img
                  src={require("../../assets/content/production/foundry/5- Ergitme (Melting).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="5-foundry"
                />
                <img
                  src={require("../../assets/content/production/foundry/6- X-Ray (X-Ray).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="6-foundry"
                />
                <img
                  src={require("../../assets/content/production/foundry/7 - Isil Islem (Heat Treatment).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="7-foundry"
                />
              </div>
            </div>
          </div>
          <div className="c-row row--no-gutter" style={{ paddingTop: "4rem" }}>
            <div className="col c-col--lg-6">
              <div className="c-caption-container">
                <div className="c-caption">
                  <div className="c-caption-title">
                    {t("development-production-machining")}
                  </div>
                  <div className="c-caption-content">
                    {t("development-production-machining-desc")}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={require("../../assets/content/production/machining/1- Robot Isleme Hucresi (Robot Machining Cell).jpg")}
                      alt=""
                      className="c-thumb-slider-item is-active"
                      id="1-machining-thumb"
                      onClick={() => changePic(1, "machining", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/machining/2- Robot Isleme Hucresi (Robot Machining Cell).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="2-machining-thumb"
                      onClick={() => changePic(2, "machining", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/machining/3- Talasli Imalat (Machining).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="3-machining-thumb"
                      onClick={() => changePic(3, "machining", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/machining/4- Tunel Yikama Makinesi (Tunnel Cleaning Machine).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="4-machining-thumb"
                      onClick={() => changePic(4, "machining", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/machining/5- Helyum Sizdirma Testi - Helium Leakage Test.jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="5-machining-thumb"
                      onClick={() => changePic(5, "machining", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/machining/6- Diamond Cut - Diamond Cut.jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="6-machining-thumb"
                      onClick={() => changePic(6, "machining", 6)}
                    />
                    <img
                      src={require("../../assets/content/production/machining/7- On yuzey fircalama -Front surface brushing.gif")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="7-machining-thumb"
                      onClick={() => changePic(7, "machining", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/machining/8- Tornalama-Turning Operation.jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="8-machining-thumb"
                      onClick={() => changePic(8, "machining", 8)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/content/production/machining/1- Robot Isleme Hucresi (Robot Machining Cell).jpg")}
                  alt="Product-1"
                  className="c-production-img is-active"
                  id="1-machining"
                />
                <img
                  src={require("../../assets/content/production/machining/2- Robot Isleme Hucresi (Robot Machining Cell).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="2-machining"
                />
                <img
                  src={require("../../assets/content/production/machining/3- Talasli Imalat (Machining).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="3-machining"
                />
                <img
                  src={require("../../assets/content/production/machining/4- Tunel Yikama Makinesi (Tunnel Cleaning Machine).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="4-machining"
                />
                <img
                  src={require("../../assets/content/production/machining/5- Helyum Sizdirma Testi - Helium Leakage Test.jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="5-machining"
                />
                <img
                  src={require("../../assets/content/production/machining/6- Diamond Cut - Diamond Cut.jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="6-machining"
                />
                <img
                  src={require("../../assets/content/production/machining/7- On yuzey fircalama -Front surface brushing.gif")}
                  alt="Product-1"
                  className="c-production-img"
                  id="7-machining"
                />
                <img
                  src={require("../../assets/content/production/machining/8- Tornalama-Turning Operation.jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="8-machining"
                />
              </div>
            </div>
          </div>
          <div className="c-row row--no-gutter u-flex-row-reverse" style={{ paddingTop: "4rem" }}>
            <div className="col c-col--lg-6">
              <div className="c-caption-container">
                <div className="c-caption">
                  <div className="c-caption-title">
                    {t("development-production-paintshop")}
                  </div>
                  <div className="c-caption-content">
                    {t("development-production-paintshop-desc")}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={require("../../assets/content/production/paintshop/1- Yukleme Robotu (Loading Robot).jpg")}
                      alt=""
                      className="c-thumb-slider-item is-active"
                      id="1-paint-thumb"
                      onClick={() => changePic(1, "paint", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/paintshop/2- On Islem Firini (Pretreatment System).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="2-paint-thumb"
                      onClick={() => changePic(2, "paint", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/paintshop/3- Transfer Robotlari (Transfer Robots).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="3-paint-thumb"
                      onClick={() => changePic(3, "paint", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/paintshop/4- Toz Boya (Powder Coating).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="4-paint-thumb"
                      onClick={() => changePic(4, "paint", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/paintshop/5- Toz Boya (Powder Coating).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="5-paint-thumb"
                      onClick={() => changePic(5, "paint", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/paintshop/6- Yas Boya (Spray Finishing).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="6-paint-thumb"
                      onClick={() => changePic(6, "paint", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/paintshop/7- Yas Boya (Spray Finishing).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="7-paint-thumb"
                      onClick={() => changePic(7, "paint", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/paintshop/8- Son Kontrol (Final Inspection).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="8-paint-thumb"
                      onClick={() => changePic(8, "paint", 8)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/content/production/paintshop/1- Yukleme Robotu (Loading Robot).jpg")}
                  alt="Product-1"
                  className="c-production-img is-active"
                  id="1-paint"
                />
                <img
                  src={require("../../assets/content/production/paintshop/2- On Islem Firini (Pretreatment System).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="2-paint"
                />
                <img
                  src={require("../../assets/content/production/paintshop/3- Transfer Robotlari (Transfer Robots).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="3-paint"
                />
                <img
                  src={require("../../assets/content/production/paintshop/4- Toz Boya (Powder Coating).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="4-paint"
                />
                <img
                  src={require("../../assets/content/production/paintshop/5- Toz Boya (Powder Coating).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="5-paint"
                />
                <img
                  src={require("../../assets/content/production/paintshop/6- Yas Boya (Spray Finishing).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="6-paint"
                />
                <img
                  src={require("../../assets/content/production/paintshop/7- Yas Boya (Spray Finishing).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="7-paint"
                />
                <img
                  src={require("../../assets/content/production/paintshop/8- Son Kontrol (Final Inspection).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="8-paint"
                />
              </div>
            </div>
          </div>
          <div className="c-row row--no-gutter" style={{ paddingTop: "4rem" }}>
            <div className="col c-col--lg-6">
              <div className="c-caption-container">
                <div className="c-caption">
                  <div className="c-caption-title">
                    {t("development-production-quality")}
                  </div>
                  <div className="c-caption-content">
                    {t("development-production-quality-desc")}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={require("../../assets/content/production/quality/1- CASS Testi - Salt Spray and Alternating Climate Test.jpg")}
                      alt=""
                      className="c-thumb-slider-item is-active"
                      id="1-quality-thumb"
                      onClick={() => changePic(1, "quality", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/quality/2-OKEK~1.JPG")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="2-quality-thumb"
                      onClick={() => changePic(2, "quality", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/quality/3- Radyal Darbe Testi (Radial Impact Test).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="3-quality-thumb"
                      onClick={() => changePic(3, "quality", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/quality/4- 3D Olcum Cihazi (3D Measurement Device).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="4-quality-thumb"
                      onClick={() => changePic(4, "quality", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/quality/5- Dinamik Yorulma Testi (Dynamic Fatigue Test).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="5-quality-thumb"
                      onClick={() => changePic(5, "quality", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/quality/6- Mikroskop (Microskop).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="6-quality-thumb"
                      onClick={() => changePic(6, "quality", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/quality/7- Spektrometre (Spectrometer).jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="7-quality-thumb"
                      onClick={() => changePic(7, "quality", 8)}
                    />
                    <img
                      src={require("../../assets/content/production/quality/8- Cekme Testi - Tensile Test.jpg")}
                      alt=""
                      className="c-thumb-slider-item"
                      id="8-quality-thumb"
                      onClick={() => changePic(8, "quality", 8)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "1.5rem",
                    marginBottom: "1.5rem",
                    justifyContent: "flex-start",
                  }}
                >
                  <Link
                    to="/development-and-production/quality"
                    className="c-button"
                  >
                    {t("development-production-moredetails")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/content/production/quality/1- CASS Testi - Salt Spray and Alternating Climate Test.jpg")}
                  alt="Product-1"
                  className="c-production-img is-active"
                  id="1-quality"
                />
                <img
                  src={require("../../assets/content/production/quality/2-OKEK~1.JPG")}
                  alt="Product-1"
                  className="c-production-img"
                  id="2-quality"
                />
                <img
                  src={require("../../assets/content/production/quality/3- Radyal Darbe Testi (Radial Impact Test).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="3-quality"
                />
                <img
                  src={require("../../assets/content/production/quality/4- 3D Olcum Cihazi (3D Measurement Device).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="4-quality"
                />
                <img
                  src={require("../../assets/content/production/quality/5- Dinamik Yorulma Testi (Dynamic Fatigue Test).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="5-quality"
                />
                <img
                  src={require("../../assets/content/production/quality/6- Mikroskop (Microskop).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="6-quality"
                />
                <img
                  src={require("../../assets/content/production/quality/7- Spektrometre (Spectrometer).jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="7-quality"
                />
                <img
                  src={require("../../assets/content/production/quality/8- Cekme Testi - Tensile Test.jpg")}
                  alt="Product-1"
                  className="c-production-img"
                  id="8-quality"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Production;
