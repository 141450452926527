import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";

const CaP = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Cevher";
  }, []);

  return (
    <div>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/powertrain.bb00d886.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("products")}
              </div>
              <h1 className="c-caption-header">{t("chassisandpowertrain")}</h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <p>
            <strong style={{ textAlign: "start" }}>
              {t("products-powertrail-title")}
            </strong>
          </p>
          <p>{t("products-powertrail-desc-1")}</p>
          <p>{t("products-powertrail-desc-2")}</p>
        </div>

        <div
          className="c-container"
          style={{ paddingTop: "4rem", paddingBottom: "4rem" }}
        >
          <img
            src={require("../../assets/content/powertrail.94db9a95.jpg")}
            style={{ maxWidth: "100%", height: "auto" }}
            alt="Powertrail"
          />
        </div>
      </div>
    </div>
  );
};

export default CaP;
