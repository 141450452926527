import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";

const Security = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Sustainability - Cevher";
  }, []);

  return (
    <div>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/information-security-policy.a954fd04.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("sustainability")}
              </div>
              <h1 className="c-caption-header">
                {t("informationsecuritypolicy")}
              </h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-security-policy-1")}</li>
            <li>{t("sustainability-security-policy-2")}</li>
            <li>{t("sustainability-security-policy-3")}</li>
            <li>{t("sustainability-security-policy-4")}</li>
            <li>{t("sustainability-security-policy-5")}</li>
            <li>{t("sustainability-security-policy-6")}</li>
            <li>{t("sustainability-security-policy-7")}</li>
            <li>{t("sustainability-security-policy-8")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Security;
