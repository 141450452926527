import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./Products.css";

const Products = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className="c-container">
        <h1 className="c-products-title">Products</h1>
        <div className="c-products-list">
          <a href="/#/products/oem" className="c-products-list-item">
            <img
              className="c-products-list-item-img"
              src={require("../../assets/Products/alloywheelsforoem.jpg")}
              alt="OEM"
            />
            <div className="c-products-list-item-title">
              {t("alloywheelsforoem")}
            </div>
          </a>
          <a href="/#/products/after-market" className="c-products-list-item">
            <img
              className="c-products-list-item-img"
              src={require("../../assets/Products/alloywheelsforaftermarket.jpg")}
              alt="AFM"
            />
            <div className="c-products-list-item-title">
              {t("alloywheelsforaftermarket")}
            </div>
          </a>
          <a
            href="/#/products/chassis-and-powertrain"
            className="c-products-list-item"
          >
            <img
              className="c-products-list-item-img"
              src={require("../../assets/Products/chassisandpowertrain.jpg")}
              alt="Chassis"
            />
            <div className="c-products-list-item-title">
              {t("chassisandpowertrain")}
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Products;
