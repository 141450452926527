import { useParams } from "react-router-dom";
import i18next from "i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import { News_EN } from "../../Constants/News";
import "./NewPage.css";

const New = () => {
  const { link } = useParams();
  const language = i18next.language;
  const newItem = News_EN.find((item) => item.link === link);

  if (newItem) {
    return (
      <>
        <Navbar />
        <div className="c-wrapper">
          <div className="c-hero">
            <div className="c-hero-background">
              <picture>
                <img
                  className="c-hero-image"
                  alt=""
                  src={require("../../assets/hero/news.2f117a4c.jpg")}
                />
              </picture>
            </div>
          </div>
          <div className="c-container">
            <div className="c-new-page-row">
              <div className="c-new-page-col">
                <div
                  className="c-new-page-container"
                  style={{ textAlign: "center", marginTop: "3rem" }}
                >
                  <div className="c-new-page-title">
                    {language === "en" ? newItem.title : newItem.title_tr}
                  </div>
                  <div
                    className="c-new-page-date"
                    style={{ marginTop: "1rem" }}
                  >
                    {newItem.date}
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: language === "en" ? newItem.body : newItem.body_tr,
                  }}
                  style={{ marginTop: "3rem" }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default New;
