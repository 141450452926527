import { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Navbar } from "../../Components/Navbar/Navbar";
import Timeline from "../../Components/Timeline";
import { News_EN } from "../../Constants/News";
import "./Home.styles.css";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Cevher";
  }, []);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <Navbar />
      <div className="c-wrapper">
        <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item className="carousel-item">
            <img
              src={require("../../assets/content/cevher_slider_banner-2.cbc16912.jpg")}
              alt="Cevher"
              className="c-slider-image"
            />
            <div className="c-carousel-caption">
              <h1 className="c-slider-title">{t("colorofautomative")}</h1>
              <p className="c-slider-description">
                {t("colorofautomativedesc")}
              </p>
              <div
                className="c-button"
                style={{ marginTop: "1rem" }}
                onClick={() => navigate("/corporate/about")}
              >
                Cevher
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item">
            <img
              src={require("../../assets/content/cevher_slider_banner-1.df2341f8.jpg")}
              alt="Cevher"
              className="c-slider-image"
            />
            <div className="c-carousel-caption">
              <h1 className="c-slider-title">{t("fromdesigntoperfection")}</h1>
              <p className="c-slider-description">
                {t("fromdesigntoperfectiondesc")}
              </p>
              <div className="carousel-buttons">
                <div className="c-button" onClick={() => navigate("/products")}>
                  {t("products")}
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item">
            <img
              src={require("../../assets/slider/sliding_banner_3.jpg")}
              alt="Cevher"
              className="c-slider-image"
            />
            <div className="c-carousel-caption">
              <h1 className="c-slider-title">{t("wecarenature")}</h1>
              <p className="c-slider-description">{t("wecarenaturedesc")}</p>
              <div
                className=" c-button"
                style={{ marginTop: "1rem" }}
                onClick={() =>
                  navigate("/sustainability/sustainability-report")
                }
              >
                {t("sustainabilityactivies")}
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        <div className="c-container">
          <div className="c-together-container">
            <div className="c-container-text-wrapper">
              <div className="c-container-text">{t("workwithworldwide")}</div>
            </div>
            <div className="c-container-img-container">
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo square"
                  alt="Volkswagen"
                  src={require("../../assets/volkswagen.png")}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo"
                  alt="Audi"
                  src={require("../../assets/audi.gif")}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo square"
                  alt="Seat"
                  src={require("../../assets/seat.jpg")}
                  style={{ maxWidth: "85%" }}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo square"
                  alt="Mercedes"
                  src={require("../../assets/mercedes_logo.png")}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo square"
                  alt="Seat"
                  src={require("../../assets/skoda.png")}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo"
                  alt="Stellantis"
                  src={require("../../assets/stellantis.png")}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo"
                  alt="Ford"
                  src={require("../../assets/ford_140.png")}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo square"
                  alt="Cupra"
                  src={require("../../assets/cupra.png")}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="c-corporate">
          <div className="c-container">
            <div className="c-container-column">
              <div className="c-caption-section">{t("corporate")}</div>
              <div className="c-caption-title c-caption-text-center">
                {t("completeperfection")}
              </div>
              <div className="c-card-row">
                <div className="card-container">
                  <div className="c-ratio c-ratio--4-3">
                    <img
                      className="c-card-img"
                      alt=""
                      src={require("../../assets/about/manufacture-1.jpg")}
                    />
                  </div>
                  <div className="c-card-body">
                    <div className="c-card-title">{t("manufacture")}</div>
                    <div className="c-card-content">
                      {t("manufacture-desc")}
                    </div>
                  </div>
                </div>
                <div className="card-container">
                  <div className="c-ratio c-ratio--4-3">
                    <img
                      className="c-card-img"
                      alt=""
                      src={require("../../assets/about/about-2.jpg")}
                    />
                  </div>
                  <div className="c-card-body">
                    <div className="c-card-title">{t("employee")}</div>
                    <div className="c-card-content">{t("employee-desc")}</div>
                  </div>
                </div>
                <div className="card-container">
                  <div className="c-ratio c-ratio--4-3">
                    <img
                      className="c-card-img"
                      alt=""
                      src={require("../../assets/about/technology-1.jpg")}
                    />
                  </div>
                  <div className="c-card-body">
                    <div className="c-card-title">{t("technology")}</div>
                    <div className="c-card-content">{t("technology-desc")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-learn-btn-wrapper">
            <Link to="/corporate/about" className="c-button">
              {t("learnmore")}
            </Link>
          </div>
        </div>
        <hr />
        <div className="c-locations-container">
          <div className="c-container">
            <div
              className="c-caption"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="c-caption-section">
                {t("customerandproductportfolio")}
              </div>
              <div className="c-caption-title c-caption-white">
                {t("customerslocation")}
              </div>
              <div
                className="c-caption-content"
                style={{ color: "white", textAlign: "center" }}
              >
                {t("customerslocation-desc")}
              </div>
            </div>
            <div className="c-map-wrapper">
              <img
                src={require("../../assets/content/customer-map.33908c8b.png")}
                alt="Map"
                className="c-map"
              />
              <div className="c-map-locations-content">
                <ul className="c-map-list">
                  <li className="c-map-list-item">{t("germany")}</li>
                  <li className="c-map-list-item">{t("italy")}</li>
                  <li className="c-map-list-item">{t("poland")}</li>
                  <li className="c-map-list-item">{t("czechia")}</li>
                  <li className="c-map-list-item">{t("spain")}</li>
                  <li className="c-map-list-item">{t("argentina")}</li>
                  <li className="c-map-list-item">{t("portugal")}</li>
                  <li className="c-map-list-item">{t("slovakia")}</li>
                  <li className="c-map-list-item">{t("mexico")}</li>
                  <li className="c-map-list-item">{t("indonesia")}</li>
                  <li className="c-map-list-item">{t("malaysia")}</li>
                  <li className="c-map-list-item">{t("russia")}</li>
                  <li className="c-map-list-item">{t("india")}</li>
                  <li className="c-map-list-item">{t("brazil")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container c-products">
          <div className="c-row row--no-gutter u-flex-row-reverse">
            <div className="col c-col--lg-6">
              <div className="c-caption-container">
                <div
                  className="c-caption"
                  style={{ padding: 0, marginBottom: "1.5rem" }}
                >
                  <div className="c-caption-section">{t("products")}</div>
                  <div className="c-caption-title c-caption-large">
                    {t("ourproducts")}
                  </div>
                  <div className="c-caption-content">
                    {t("ourproducts-desc")}
                  </div>
                </div>
                <Link to="/products" className="c-button">
                  {t("details")}
                </Link>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/content/our-products.51d44431.jpg")}
                  alt="Product-1"
                  className="c-products-img"
                />
              </div>
            </div>
          </div>
          <div
            className="c-row row--no-gutter"
            style={{
              marginTop: "3rem",
            }}
          >
            <div className="col c-col--lg-6">
              <div className="c-caption-container">
                <div
                  className="c-caption"
                  style={{ padding: 0, marginBottom: "1.5rem" }}
                >
                  <div className="c-caption-section">
                    {t("developmentandproduction")}
                  </div>
                  <div className="c-caption-title c-caption-large">
                    {t("production")}
                  </div>
                  <div className="c-caption-content">
                    {t("production-desc")}
                  </div>
                </div>
                <Link
                  to="/development-and-production/production"
                  className="c-button"
                >
                  {t("details")}
                </Link>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/content/production.9fb36f20.jpg")}
                  alt="Product-2"
                  className="c-products-img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="c-corporate">
          <div className="c-container ">
            <div className="c-corporate-wrapper">
              <div className="c-corporate-content-container">
                <div className="c-corporate-content-wrapper">
                  <div className="c-caption c-caption-text-center">
                    <div className="c-caption-section">{t("corporate")}</div>
                    <div className="c-caption-title c-caption-large">
                      {t("history-tale")}
                    </div>
                    <div className="c-caption-content">
                      {t("history-tale-desc")}
                    </div>
                  </div>
                  <a href="/#/corporate/history" className="c-button">
                    {t("details")}
                  </a>
                </div>
              </div>
              <div className="c-corporate-timeline">
                <div style={{ overflow: "hidden", position: "relative" }}>
                  <div className="c-timeline-opacity-effect top" />
                  <div className="c-timeline-opacity-effect bot" />
                  <Timeline scrollable={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="c-caption c-news"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="c-caption-section">{t("news")}</div>
            <div className="c-caption-title">{t("recentnews")}</div>
          </div>
          <div className="c-news-wrapper">
            {News_EN.slice(0, 3).map((item) => (
              <Link to={item.link} className="c-news-card" key={item.link}>
                <div className="c-news-card-image-container c-ratio--16-9 c-ratio">
                  <picture>
                    <img src={item.img} className="c-news-img" alt="#" />
                  </picture>
                </div>
                <div className="c-news-body">
                  <div className="c-news-title">{item.title}</div>
                  <div className="c-news-date">{item.date}</div>
                  <div className="c-news-content">{item.desc}</div>
                </div>
              </Link>
            ))}
          </div>
          <Link
            to="/news"
            className="c-button"
            style={{
              alignSelf: "center",
              marginTop: "1rem",
            }}
          >
            {t("allnews")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
