import { useTranslation } from "react-i18next";

const Timeline = (props) => {
  const { t } = useTranslation();

  return (
    <div className={props.scrollable ? "c-corporate-timeline-scroll" : ""}>
      <div className="c-timeline-lines" />
      <div className="c-timeline">
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="1955"
              src={require("../../assets/uploads/1955_5bc4773d3e291.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1955</div>
            <div>{t("corporate-history-1955")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="1967"
              src={require("../../assets/uploads/1967_5bc477364cd69.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1967</div>
            <div>{t("corporate-history-1967")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="1968"
              src={require("../../assets/uploads/1968_5bc4771eb8d79.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1968</div>
            <div>{t("corporate-history-1968")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="1975"
              src={require("../../assets/uploads/1975_5e40e8c4dd948.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1975</div>
            <div>{t("corporate-history-1975")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="1980"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1980</div>
            <div>{t("corporate-history-1980")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="1983"
              src={require("../../assets/uploads/1983_5bc4770f00016.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1983</div>
            <div>{t("corporate-history-1983")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              src={require("../../assets/uploads/1984_5bc47701ed7b8.jpg")}
              alt="1984"
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1984</div>
            <div>{t("corporate-history-1984")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
              alt="1985"
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1985</div>
            <div>{t("corporate-history-1985")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
              alt="1986"
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1986</div>
            <div>{t("corporate-history-1986")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              src={require("../../assets/uploads/1990_5bc476f352a00.jpg")}
              alt="1990"
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1990</div>
            <div>{t("corporate-history-1990")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              src={require("../../assets/uploads/1995_5bc5ca87eef5b.jpg")}
              alt="1995"
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1995</div>
            <div>{t("corporate-history-1995")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              src={require("../../assets/uploads/1996_5bc476db530e7.jpg")}
              alt="1996"
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1996</div>
            <div>{t("corporate-history-1996")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              src={require("../../assets/uploads/1997_5bc476d1f2938.jpg")}
              alt="1997"
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1997</div>
            <div>{t("corporate-history-1997")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
              alt="1997"
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1997</div>
            <div>{t("corporate-history-1997-2")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              src={require("../../assets/uploads/1997_5bc4769d40aa7.jpg")}
              alt="1997"
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1997</div>
            <div>{t("corporate-history-1997-3")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              src={require("../../assets/uploads/1997_5bc47692c11e7.jpg")}
              alt="1997"
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1997</div>
            <div>{t("corporate-history-award")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              src={require("../../assets/uploads/1997_5bc47692c11e7.jpg")}
              alt="1997"
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1998</div>
            <div>{t("corporate-history-award")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              src={require("../../assets/uploads/1997_5bc47692c11e7.jpg")}
              alt="1997"
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">1999</div>
            <div>{t("corporate-history-award")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              src={require("../../assets/uploads/1997_5bc47692c11e7.jpg")}
              alt="1997"
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2000</div>
            <div>{t("corporate-history-award")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="1980"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2000</div>
            <div>{t("corporate-history-2000")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2000"
              src={require("../../assets/uploads/2000_5bc5cb00cd5a1.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2000</div>
            <div>{t("corporate-history-2000-2")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2002"
              src={require("../../assets/uploads/2002_5bc4763ba927c.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2002</div>
            <div>{t("corporate-history-2002")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2003"
              src={require("../../assets/uploads/2003_5bc476300d767.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2003</div>
            <div>{t("corporate-history-2003")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2003"
              src={require("../../assets/uploads/2003-cigli.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2003</div>
            <div>{t("corporate-history-2003-2")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2003"
              src={require("../../assets/uploads/2003_5bc47602729e7.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2003</div>
            <div>{t("corporate-history-2003-3")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2004"
              src={require("../../assets/uploads/2004_5bc475f13b862.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2004</div>
            <div>{t("corporate-history-2004")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2004"
              src={require("../../assets/uploads/2004_5bc475c78483e.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2004</div>
            <div>{t("corporate-history-2004-2")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2004"
              src={require("../../assets/uploads/2004_5bc5cb40246c4.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2004</div>
            <div>{t("corporate-history-2004-3")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2003"
              src={require("../../assets/uploads/2003_5bc47602729e7.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2004</div>
            <div>{t("corporate-history-2004-4")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="1980"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2005</div>
            <div>{t("corporate-history-2005")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="1980"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2006</div>
            <div>{t("corporate-history-2006")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="1980"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2008</div>
            <div>{t("corporate-history-2008")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="1980"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2009</div>
            <div>{t("corporate-history-2009")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2009"
              src={require("../../assets/uploads/2009_5bc4759d1ae4b.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2009</div>
            <div>{t("corporate-history-2009-2")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2013"
              src={require("../../assets/uploads/2013_5bc475906b2f2.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2013</div>
            <div>{t("corporate-history-2013")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2013"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2013</div>
            <div>{t("corporate-history-2013-2")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2014"
              src={require("../../assets/uploads/2014_5bc34fb62c685.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2014</div>
            <div>{t("corporate-history-2014")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2013"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2016</div>
            <div>{t("corporate-history-2016")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2018"
              src={require("../../assets/uploads/2018_5bc5cc6fedc52.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2018</div>
            <div>{t("corporate-history-2018")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2018"
              src={require("../../assets/uploads/2018_5bc34fae619b7.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2018</div>
            <div>{t("corporate-history-2018-2")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2018"
              src={require("../../assets/uploads/2018_5bd310996e5b8.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2018</div>
            <div>{t("corporate-history-2018-3")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2018"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2021</div>
            <div>{t("corporate-history-2021")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2018"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2021</div>
            <div>{t("corporate-history-2021-2")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2018"
              src={require("../../assets/uploads/2021_secondfactory.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2021</div>
            <div>{t("corporate-history-2021-3")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2018"
              src={require("../../assets/uploads/2022_production.jpg")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2022</div>
            <div>{t("corporate-history-2022")}</div>
          </div>
        </div>
        <div className="c-timeline-item">
          <div className="c-timeline-image c-ratio">
            <img
              className="c-ratio-element"
              alt="2018"
              src={require("../../assets/uploads/timeline-placeholder.53bed0ca.png")}
            />
          </div>
          <div className="c-timeline-content">
            <div className="c-timeline-date">2022</div>
            <div>{t("corporate-history-2022-2")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
