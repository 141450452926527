import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter } from "react-router-dom";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

import "./index.css";
import App from "./App";

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: "en",
    fallbackLng: "en",
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
    react: {
      useSuspense: false,
    },
  });

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <HashRouter forceRefresh>
    <App />
  </HashRouter>
);
