export const CustomerList = [
  {
    img: require("../assets/clients/volkswagen_5e995a3834b89.png"),
    alt: "Volkswagen",
  },
  {
    img: require("../assets/clients/audi_5e995c6ae38f5.png"),
    alt: "Audi",
  },
  {
    img: require("../assets/clients/seat_5e995a5d2a973.png"),
    alt: "Seat",
  },
  {
    img: require("../assets/clients/mercedes_5e995bb2e62f9.png"),
    alt: "Mercedes",
  },
  {
    img: require("../assets/clients/skoda.png"),
    alt: "Skoda",
  },
  {
    img: require("../assets/clients/stellantis_610808df67778.png"),
    alt: "Stellantis",
  },
  {
    img: require("../assets/clients/ford.png"),
    alt: "Ford",
  },
  {
    img: require("../assets/clients/cupra.png"),
    alt: "Cupra",
  },
  {
    img: require("../assets/clients/toyota.png"),
    alt: "Toyota",
  },
  {
    img: require("../assets/clients/renault.png"),
    alt: "Renault",
  },
  {
    img: require("../assets/clients/borg-warner.png"),
    alt: "Borg Warner",
  },
  {
    img: require("../assets/clients/ibc.png"),
    alt: "IBC",
  },
  {
    img: require("../assets/clients/dacia.png"),
    alt: "Dacia",
  },
  {
    img: require("../assets/clients/nissan.png"),
    alt: "Nissan",
  },
  {
    img: require("../assets/clients/ihi.png"),
    alt: "IHI",
  },
  {
    img: require("../assets/clients/lombardini.png"),
    alt: "Lombardini",
  },
  {
    img: require("../assets/clients/iveco.png"),
    alt: "Iveco",
  },
  {
    img: require("../assets/clients/saab.png"),
    alt: "Saab",
  },
  {
    img: require("../assets/clients/cadillac.png"),
    alt: "Cadillac",
  },
  {
    img: require("../assets/clients/vauxhall.png"),
    alt: "Vauxhall",
  },
  {
    img: require("../assets/clients/saturn.png"),
    alt: "Saturn",
  },
  {
    img: require("../assets/clients/holden.png"),
    alt: "Holden",
  },
  {
    img: require("../assets/clients/alfa-romeo.png"),
    alt: "Alfa Romeo",
  },
  {
    img: require("../assets/clients/mack.png"),
    alt: "Mack",
  },
  {
    img: require("../assets/clients/volvo.png"),
    alt: "Volvo",
  },
  {
    img: require("../assets/clients/peugeot.png"),
    alt: "Peugeot",
  },
  {
    img: require("../assets/clients/zf.png"),
    alt: "Zf",
  },
  {
    img: require("../assets/clients/general-motors.png"),
    alt: "GM",
  },
  {
    img: require("../assets/clients/magirus-deutz.png"),
    alt: "Magirus",
  },
  {
    img: require("../assets/clients/luk.png"),
    alt: "Luk",
  },
  {
    img: require("../assets/clients/mobylette.png"),
    alt: "Mobylette",
  },
  {
    img: require("../assets/clients/ford-otosan.png"),
    alt: "Ford Otosan",
  },
  {
    img: require("../assets/clients/auer.png"),
    alt: "Auer",
  },
  {
    img: require("../assets/clients/chrysler.png"),
    alt: "Chrysler",
  },
  {
    img: require("../assets/clients/man.png"),
    alt: "Man",
  },
  {
    img: require("../assets/clients/austin.png"),
    alt: "Austin",
  },
  {
    img: require("../assets/clients/etibank.png"),
    alt: "Etibank",
  },
  {
    img: require("../assets/clients/bmc.png"),
    alt: "Bmc",
  },
  {
    img: require("../assets/clients/morris.png"),
    alt: "Morris",
  },
  {
    img: require("../assets/clients/bmw_5e2b119b9ac02.png"),
    alt: "BMW",
  },
  {
    img: require("../assets/clients/mini-cooper_5e3d417958657.png"),
    alt: "Mini Cooper",
  },
];
