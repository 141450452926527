import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";

const Enviromental = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Sustainability - Cevher";
  }, []);

  return (
    <div>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/environment-policy.deb9f609.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("sustainability")}
              </div>
              <h1 className="c-caption-header">
                {t("environmentalpolicyandactivities")}
              </h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
        >
          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities")}</strong>
          </p>
          <p>
            <br />
            {t("sustainability-environmental-ouractivities-1")}
          </p>
          <p>
            <br />
            {t("sustainability-environmental-ouractivities-2")}
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-3")}</li>
            <li>{t("sustainability-environmental-ouractivities-4")}</li>
            <li>{t("sustainability-environmental-ouractivities-5")}</li>
            <li>{t("sustainability-environmental-ouractivities-6")}</li>
            <li>{t("sustainability-environmental-ouractivities-7")}</li>
          </ul>
          <p>
            <br />
            {t("sustainability-environmental-ouractivities-regulations")}
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-1")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-2")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-3")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-4")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-5")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-6")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-7")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-8")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-9")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-10")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-11")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-12")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-13")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-14")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-15")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-16")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-17")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-18")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-19")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-20")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-21")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-22")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-23")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-24")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-25")}
            </li>
            <li>
              {t("sustainability-environmental-ouractivities-regulations-26")}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Enviromental;
